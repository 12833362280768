enum UxIconEnum {
  // Assets
  ENGLISH = "assets:icons/reading-icon.png",
  STAR = "assets:icons/star.svg",
  BETA = "assets:icons/iconBeta.svg",
  PENDOHELP = "assets:icons/icon-pendo-badge.svg",
  MPP_APP = "assets:icons/icon_mpp.png",
  NO_CLASSES = "assets:icons/No_Classes.svg",
  // Font Awesome
  ARROW_LEFT = "arrow-left",
  SORT_DOWN = "sort-down",
  ARROW_RIGHT = "arrow-right",
  ARROW_CIRCLE_LEFT = "arrow-circle-left",
  ARROW_CIRCLE_RIGHT = "arrow-circle-right",
  ARROW_SQUARE_LEFT = "arrow-square-left",
  ARROW_SQUARE_RIGHT = "arrow-square-right",
  ANGLE_DOWN = "angle-down",
  BARS = "bars",
  BOOKS = "books",
  BOOK_OPEN = "book-open",
  BOOK_READER = "book-reader",
  CARET_DOWN = "caret-down",
  CARET_UP = "caret-up",
  CALCULATOR_ALT = "calculator-alt",
  CHEVRON_DOWN = "chevron-down",
  CHEVRON_UP = "chevron-up",
  CHEVRON_LEFT = "chevron-left",
  CHEVRON_RIGHT = "chevron-right",
  CHECK = "check",
  CHECK_CIRCLE = "check-circle",
  CHECK_SQUARE = "check-square",
  CIRCLE = "circle",
  CIRCLE_NOTCH = "circle-notch",
  DOT_CIRCLE = "dot-circle",
  ELLIPSIS_V = "ellipsis-v",
  FILE_CHECK = "file-check",
  ID_BADGE = "id-badge",
  LIGHTBULB = "lightbulb",
  LIGHTBULB_ON = "lightbulb-on",
  LOCK = "lock",
  MAP_MARKER_ALT = "map-marker-alt",
  MINUS_CIRCLE = "minus-circle",
  NONE = "none",
  POWER_OFF = "power-off",
  PLUS = "plus",
  PLUS_CIRCLE = "plus-circle",
  QUESTION_CIRCLE = "question-circle",
  USER = "user",
  TH = "th",
  TH_LIST = "th-list",
  TIMES = "times",
  TIMES_CIRCLE = "times-circle",
  WRENCH = "wrench",
  CHART_BAR = "chart-bar",
  USER_CIRCLE = "user-circle",
  USER_EDIT = "user-edit",
  USERS = "users",
  FORT_AWESOME = "fort-awesome",
  SEARCH = "search",
  FILTER = "filter",
  COGS = "cogs",
  COG = "cog",
  UNIVERSITY = "university",
  SQUARE = "square",
  CUBES = "cubes",
  CHILD = "child",
  SCHOOL = "school",
  GRADUATION_CAP = "graduation-cap",
  CLONE = "clone",
  INFO_CIRCLE = "info-circle",
  EXCLAMATION = "exclamation",
  SYNC_ALT = "sync-alt",
  FILE_SEARCH = "file-search",
  CALENDAR = "calendar",
  LOCK_ALT = "lock-alt"
}
export default UxIconEnum;
