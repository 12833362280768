// @ts-nocheck
/**
 * @function assetImport
 * @param {string} id id to import
 * @description Uses require to import from assets or themes
 * @returns {string} import
 */

const assetImport = (id: string): string => require("../../assets/" + id.substring(7)).default;

export default assetImport;
